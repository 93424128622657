import { render, staticRenderFns } from "./selectDoctor.vue?vue&type=template&id=dd6c0c74&scoped=true&"
import script from "./selectDoctor.vue?vue&type=script&lang=js&"
export * from "./selectDoctor.vue?vue&type=script&lang=js&"
import style0 from "./selectDoctor.vue?vue&type=style&index=0&id=dd6c0c74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd6c0c74",
  null
  
)

export default component.exports