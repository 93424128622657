<template>
    <div>
        <div class="m-wrapper04">
            <div class="m-tab03 weui-tab">
                <div class="weui-navbar select-tab">
                    <a @click="selectTab('doctor')" class="weui-navbar__item" :class="currentTab === 'doctor' ? 'select-tab-active' : ''">
                        <span class="tab">按专家</span>
                    </a>
                    <a @click="selectTab('date')" class="weui-navbar__item" :class="currentTab === 'date' ? 'select-tab-active' : ''">
                        <span class="tab">按日期</span>
                    </a>
                </div>
                <div class="weui-tab__bd">
                    <!-- 按医生 -->
                    <div v-if="currentTab === 'doctor'" class="weui-tab__bd-item weui-tab__bd-item--active">
                        <doctor-info :doctorList="doctorList" :type='currentTab' :hosName="hosName"></doctor-info>
                    </div>
                    <!-- 按日期 -->
                    <div v-else class="weui-tab__bd-item">
                        <div class="m-doctor01">
                            <div class="m-time-scroll01">
                                <div class="swiper-container">
                                    <ul class="swiper-wrapper">
                                        <li v-for="date in dateList" :key="date.number" class="swiper-slide" :class="currentDate === date.number ? 'current' : ''" @click="toggleDate(date.number)">
                                            <div class="time01">{{date.day}}</div>
                                            <div class="time02">{{date.date}}</div>
                                        </li>
                                    </ul>
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                </div>
                            </div>
                             <doctor-info :doctorList="doctorList" :type='currentTab'></doctor-info>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import DoctorInfo from './doctorInfo'
export default {
    data() {
        return {
            currentTab: 'doctor',
            currentDate: 1,
            // doctorList: [{
            //     id: 1,
            //     name: '张三',
            //     jobTitle: '主任医师',
            //     status: '可挂号',
            //     type: '普通门诊',
            //     price: 50,
            //     payType: '到院付',
            //     skill: '擅长：消化专业慢性胃炎、消化性溃疡、腹泻、胆道疾病，肝炎、肝硬化并发症等消化道疾病的诊治并熟练掌握胃镜的检查诊断',
            //     avadar: require('@/assets/images/avatar-2.png'),
            //     recordStatus: 1
            // }, {
            //     id: 2,
            //     name: '张三',
            //     jobTitle: '主任医师',
            //     status: '可挂号',
            //     type: '普通门诊',
            //     price: 50,
            //     payType: '到院付',
            //     skill: '擅长：消化专业慢性胃炎、消化性溃疡、腹泻、胆道疾病，肝炎、肝硬化并发症等消化道疾病的诊治并熟练掌握胃镜的检查诊断',
            //     avadar: require('../../assets/images/avatar-2.png'),
            //     recordStatus: 2
            // }],
            dateList: [{
                number: 1,
                day: '星期一',
                date: '3-22'
            },{
                number: 2,
                day: '星期二',
                date: '3-23'
            },{
                number: 3,
                day: '星期三',
                date: '3-24'
            },{
                number: 4,
                day: '星期四',
                date: '3-25'
            },{
                number: 5,
                day: '星期五',
                date: '3-26'
            },{
                number: 6,
                day: '星期六',
                date: '3-27'
            },{
                number: 7,
                day: '星期日',
                date: '3-28'
            }],
            depName: '',
            hosName: ''
        }
    },
    components: {
        DoctorInfo
    },
    computed: {
        ...mapState({
            doctorList: state => state.selectDoctor.doctorList
        })
    },
    methods: {
        selectTab(target) {
            if(target === this.currentTab) return;
            this.currentTab = target;
        },
        toggleDate(num) {
            if(num === this.currentDate) return;
            this.currentDate = num;
        }
    },
    mounted() {
        // 获取地址栏传递过来的参数
        this.hosName = this.$route.query['hosName']
        let id = this.$route.query['hosDeptId']
        // 查询医生列表信息
        this.$store.dispatch('getSimpleDoctorList', {
            hospitalDepartmentId: id,
            pageIndex: 1
        })
        new Swiper('.swiper-container', {
            spaceBetween: 0,
            slidesPerView:'auto',
            freeMode: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            observer:true,
            observeParents:true,
        });
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.select-tab {
    width: 100%;
    .tab {
        height: 42px;
        display: inline-block;
    }

    &-active {
        .tab {
            color: $mainColor;
            border-bottom: 2px solid $mainColor;
        }
    }
}

.weui-navbar__item.weui-bar__item--on:after {
    border: none;
}

.weui-tab__bd {
    padding-top: 48px;
}

.doctor-intro01 {
    text-align: left;
}

.swiper-container::-webkit-scrollbar {
    display: none;
}

.swiper-wrapper {
    overflow: overlay;
}

.m-time-scroll01  {
    .swiper-slide.current {
        background: $mainColor;
    }
}


</style>