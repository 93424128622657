<template>
    <ul class="m-doctor-list04">
        <li class="doctor-con01" v-for="doctor in doctorList" :key="doctor.id">
            <a @click="watchDoctorDetail(doctor.doctorSn)" class="doctor-box01">
                <img :src="doctor.photoUri || avadarImg" class="doctor-avatar02">
                <div class="m-doctor-info01">
                    <div class="doctor-info-con02">
                        <h5 class="doctor-name02">{{doctor.doctorName}}</h5>
                        <span class="doctor-type01">{{doctor.lczc}}</span>
                        <!-- <span class="doctor-tag01">{{ghObj[doctor['doctorService_gh']]}}</span> -->
                    </div>
                    <!-- <div v-show="type === 'date'" class="doctor-info-con03">
                        <span class="doctor-type01">{{doctor.type}}</span>
                        <span class="time-tag time-tag01"></span>
                        <span class="price03">{{doctor.price}}元</span>
                        <span class="doctor-type01">({{doctor.payType}})</span>
                    </div> -->
                    <div class="doctor-info-con03">
                        <span class="doctor-type01">{{depName}}</span>
                    </div>
                    <p class="doctor-intro01">
                        {{doctor.skill}}
                    </p>
                </div>
            </a>
            <a @click="toRecord(doctor['doctorService_gh'], doctor.doctorSn)" class="btn04" :class="doctor['doctorService_gh'] === 2 ? '' : 'disable'" v-html="doctor['doctorService_gh'] === 2 ? '预约' : '约满'"></a>
        </li>
    </ul>
</template>
<script>
export default {
    props: ['doctorList', 'type', 'hosName'],
    data() {
        return {
            ghObj: {
                1: '',
                2: '可挂号'
            },
            avadarImg: require('../../assets/images/avatar-2.png'),
            depName: ''
        }
    },
    mounted() {
        this.depName = this.$route.query['depName']
        console.log('doctorList,', this.doctorList, this.type)
    },
    methods: {
        watchDoctorDetail(doctorSn) {
            // 查看医生主页
            this.$router.push(`/doctorDetail?doctorSn=${doctorSn}&hosName=${this.hosName}&depName=${this.depName}`)
        },
        toRecord(status, doctorSn) {
            if(status !== 2) return
            this.$router.push(`/appointment?doctorSn=${doctorSn}&hospitalId=${this.$route.query['hospitalId']}`)
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.doctor-intro01 {
    text-align: left;
}
.btn04 {
    background: $mainColor;
    &.disable {
        background: #ccc;
    }
}
</style>